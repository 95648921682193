

































import Vue from 'vue'
import Component from 'vue-class-component'
import { mapGetters } from 'vuex';
import InsightFilters from '../components/InsightFilters.vue'
import PostBox from '../components/PostBox.vue';
import { InsightItem } from '../store/types';
import { BPagination } from 'bootstrap-vue';
import { TableQuery } from '@/modules/shared/utils/TableQuery';
import { Watch } from 'vue-property-decorator';
import SygniLoader from '@/components/layout/SygniLoader.vue';
import GenproxPageHeading from '@/components/layout/GenproxPageHeading.vue';
import SygniInput from '@/components/inputs/SygniInputError.vue';
import ChevronLeftIcon from '@/assets/icons/heroicons/ChevronLeftIcon.vue';
import ChevronRightIcon from '@/assets/icons/heroicons/ChevronRightIcon.vue';

@Component({
  components: { InsightFilters, PostBox, BPagination, SygniLoader, GenproxPageHeading, SygniInput, ChevronLeftIcon, ChevronRightIcon },
  computed: {
    ...mapGetters('insights', {
      insights: 'getInsights',
    })
  }
})
export default class InsightsModule extends Vue {
  insights!: Array<InsightItem>;
  currentPage: number = 1;
  isLoading: boolean = false;
  localQuery: TableQuery;

  onFiltersChange(filtersQuery?: string): void {
    this.isLoading = true;
    this.$store.commit('insights/setInsightsTableFiltersQuery', filtersQuery);
    this.currentPage = 1;
    this.$nextTick(() => {
      this.getInsights();
    });
  }

  get filtersQuery() {
    return this.$store.getters['insights/getInsightsTableFiltersQuery'];
  }

  get perPage() {
    return this.$store.getters['insights/getInsightsTablePerPage'];
  }

  get totalCount() {
    return this.$store.getters['insights/getInsightTableTotalCount'];
  }

  setOffsetQuery() {
    this.isLoading = true;
    const offset = this.perPage * (this.currentPage - 1);
    this.$store.commit('insights/setInsightTableOffsetQuery', offset);
    this.getInsights();
  }

  async getInsights() {
    await this.$store.dispatch('insights/getInsights', this.filtersQuery);
    const appContentDiv: HTMLDivElement = document.querySelector('.app-content');
    appContentDiv?.scrollTo({top: 0, behavior: 'smooth'});
    this.isLoading = false;
  }

  @Watch('currentPage') onCurrentPageChange() {
    this.setOffsetQuery();
  }
  
  async beforeMount() {
    this.isLoading = true;
    await this.$store.dispatch('profile/getDictionaries');
    await this.getInsights();
  }
}
