var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "dashboardModule",
    staticClass: "dashboard-module"
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('GenproxPageHeading', {
    attrs: {
      "title": "Insights",
      "description": "Check our last updates and tutorials."
    }
  }), _c('section', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.insights.length,
      expression: "insights.length"
    }],
    staticClass: "m-post-list"
  }, [_c('div', {
    staticClass: "m-post-list__container"
  }, _vm._l(_vm.insights, function (insight) {
    return _c('div', {
      key: insight.id,
      staticClass: "m-post-list__box"
    }, [_c('post-box', {
      attrs: {
        "details": insight
      }
    })], 1);
  }), 0), _c('b-pagination', {
    attrs: {
      "align": "center",
      "total-rows": _vm.totalCount,
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "limit": _vm.perPage,
      "first-number": true,
      "last-number": true,
      "hide-goto-end-buttons": true
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('ChevronLeftIcon')];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('ChevronRightIcon')];
      },
      proxy: true
    }]),
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }